.reset-subtitle {
    font-size: 36px;
    letter-spacing: 5px;
    font-weight: normal;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.reset-description{
    color: #fff;
    text-align: center;
    margin: 10px
}
/*update 16/11/23 */
@media (max-width: 512px) {
    .reset-subtitle {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .reset-description{
        margin: 0;
        margin-bottom: 10px
    }
}
/*update 16/11/23 end*/