#gap{
  gap: 20px 10px !important;
}

.dynamic-screenshot-dashboards {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(216, 216, 216, .4);
    margin: 140px 30px 65px;
    padding: 15px 15px 40px;
    overflow: auto;
    display: flex;
    align-items: stretch;
    background-color: #2C476C;
    border-radius: 11px;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 220px);
    z-index: 2;
}
.account-dashboards--content{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5,calc(20% - 16px));
    grid-template-rows: repeat(3,32%);
    gap: 20px;
    overflow: hidden auto;
    padding: 15px;
}
.account-dashboards--content::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
}

.account-dashboards--content::-webkit-scrollbar-track {
    background-color: #e9e9e9;
}

.account-dashboards--content::-webkit-scrollbar-thumb {
    background-color: #979797;
    /*border-radius: 4px;*/
}
.dynamic-screen-shot-empty-data{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dynamic-screen-shot-empty-data h2{
    font-family: "sans-serif, Arial";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 19px;
    letter-spacing: 1px;
/* identical to box height */
    text-transform: uppercase;
    color: white;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .account-dashboards--content {
        /*grid-template-columns: repeat(4,minmax(200px,25%));*/
    }
    .account-dashboards--content article:nth-child(n+13){
        /*display: none;*/
    }
}
.top-btns {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: stretch;
    gap: 1px;
    width: 100%;
    padding-left: 80px;
    justify-content: space-between;
}
.top-btns>div{
    display: flex;
    gap: 10px;
    margin: max(calc(100vh/56),5px);
}
.account-dashboards--pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;

}
.dynamic-screenshot-dashboards ul.MuiPagination-ul li:first-child,ul.MuiPagination-ul  li:last-child {
    background-color: #F3F2F7;
    border-radius: 50%;
    margin: 0px 10px;
}
.dynamic-screenshot-dashboards ul.MuiPagination-ul li:nth-child(2) {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    -moz-border-radius-bottomleft: 20px;
    -moz-border-radius-bottomleft: 20px;
}

.dynamic-screenshot-dashboards ul.MuiPagination-ul li:nth-last-child(-2n+2) {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    -moz-border-radius-bottomright: 20px;
    -moz-border-radius-topright: 20px;
}
.dynamic-screenshot-dashboards ul.MuiPagination-ul li:not(:first-child,:last-child) {
    background-color: #D9D9D9;
    height: 30px;
}
.dynamic-screenshot-dashboards ul.MuiPagination-ul li button{
    margin: 0px;
}
.dynamic-screenshot-dashboards ul.MuiPagination-ul li:not(:first-child,:last-child) button{
    top: -1px;
}
.dynamic-screenshot-dashboards ul.MuiPagination-ul li button.Mui-selected{
    background-color: #3985EC;
    color: #fff;
}
.account-dashboards--pagination .small{
    display: none;
}
.account-dashboards--pagination .medium{
    display: unset;
}

@media screen and (min-width: 900px) and (max-width: 1800px) {
    .top-btns > div {
        margin: 0;
    }

    .dynamic-screenshot-dashboards {
        margin: 105px 20px 20px;
        padding: 13px 20px;
        height: calc(100vh - 135px);
        z-index: 2;
    }
    .dynamic-screenshot-dashboards ul.MuiPagination-ul li:not(:first-child,:last-child){
        height: 25px;
    }
    .account-dashboards--pagination .small{
        display: unset;
    }
    .account-dashboards--pagination .medium{
        display: none;
    }
}
@media screen and (min-width: 950px) and (max-width: 1400px) {
    .dynamic-screenshot-dashboards{
        padding: 40px 40px;
    }
    .account-dashboards--content{
        grid-template-columns: repeat(3,calc(33% - 16px));
        grid-template-rows: repeat(auto-fill,200px);
        justify-content: space-between;
        padding: 20px 15px;
    }
}
@media screen and (min-width: 650px) and (max-width: 950px) {
    .dynamic-screenshot-dashboards{
        margin: 130px 0px 0px 0px;
    }
    .dynamic-screenshot-dashboards{
        padding: 10px 10px;
        height: calc(100vh - 130px);
    }
    .account-dashboards--content{
        grid-template-columns: repeat(2,calc(50% - 16px));
        grid-template-rows: repeat(auto-fill,200px);
        justify-content: space-between;
        padding: 20px 15px;
    }
}
@media screen and (min-width: 300px) and (max-width: 650px) {
    .dynamic-screenshot-dashboards{
        margin: 130px 0px 0px 0px;
    }
    .dynamic-screenshot-dashboards{
        padding: 10px 10px;
        height: calc(100vh - 130px);
    }
    .account-dashboards--content{
        grid-template-columns: repeat(1,calc(90% - 16px));
        grid-template-rows: repeat(auto-fill,200px);
        justify-content: center;
        padding: 20px 15px;
    }
}
