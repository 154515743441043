.mapSliderContainer {
    position: fixed;
    width: 99.5%;
    height: 113px;
    background-color: #fff;
    bottom: 0;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.noBody {
    height: 48px;
}

.mapSliderHeader {
    background-color: #2C476C;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    color: #fff;
    text-transform: uppercase;
    gap: 15px;
}
.mapSliderHeaderTitle>div{
    padding: 0 20px;
}
.mapSliderHeaderTitle {
    min-width: 80px;
    text-align: center;
}

.mapSliderBody {
    height: 62px;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.mapSlider {
    flex: 1;
    padding-right: 80px;
    padding-left: 80px;
}

.mapSlider :global .splide__arrow {
    opacity: 0.4;
    background-color: transparent;
}

.mapSliderItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F7F8;
    padding: 5px 8px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

.mapSliderItem:hover {
    background-color: #f1f2f4;
}

.mapSliderItemTitle {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leftArrowBtn {
    transform: rotate(-90deg);
    cursor: pointer;
}

.rightArrowBtn {
    transform: rotate(90deg);
    cursor: pointer;
}


.buttonIsDisabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (min-width: 2560px) {
    .mapSliderContainer {
        height: 180px;
    }
    .mapSliderHeader {
        padding: 20px;
    }
    .mapSliderHeaderTitle {
        font-size: 32px;
    }

    .mapSliderBody {
        height: 90px;
    }

    .mapSliderItem {
        font-size: 25px;
        height: 56px;
    }

    .mapSlider {
        flex: 1;
        padding-right: 150px;
        padding-left: 150px;
    }

}


@media (min-width: 1401px) and (max-width: 1600px) {
    .mapSliderContainer {
        height: 88px;
    }

    .mapSliderHeader {
        padding: 7px;
    }

    .mapSliderHeaderTitle {
        font-size: 14px;
    }

    .mapSliderItem {
        font-size: 13px;
    }

    .mapSliderBody {
        height: 48px;
    }

    .noBody {
        height: 38px;
    }
}


@media screen and (max-width: 1400px) {
    .mapSliderContainer {
        height: 74px;
    }

    .mapSliderHeader {
        padding: 5px;
    }

    .mapSliderHeaderTitle {
        font-size: 12px;
    }

    .mapSliderItem {
        font-size: 12px;
    }

    .mapSliderBody {
        height: 40px;
    }

    .noBody {
        height: 34px;
    }

    .mapSlider {
        flex: 1;
        padding-right: 70px;
        padding-left: 70px;
    }

}
