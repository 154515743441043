.react-calendar {
    color: #000;
    padding: 15px 25px;
    border-radius: 12px;
}
.react-datepicker-wrapper {
    width: 100%;
    height: 100%;
}
.react-datepicker-wrapper {
    border: none;
    width: 100%;
}
.phone-box{
    margin-bottom: 10px;
}
.react-datepicker {
    width: 100%;
    height: 100%;
    background-color: #fff !important;
    border: none !important;
    border-radius: 10px !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #2C476C !important;
    border-width: 2px 2px 0 0 !important;
    top: 15px !important;
}
.react-datepicker__day, .react-datepicker__time-name {
    color: #2C476C !important;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected, .react-datepicker__day--selected {
    border-radius: 3.3rem !important;
    background-color: #F3BFDA !important;
    color: #2C476C !important;
}
.react-datepicker__day--outside-month {
    color: #B2B2B2 !important;
}
.react-datepicker__header {
    background-color: #ffffff !important;
    border-bottom: none !important;
}
.react-datepicker__current-month {
    display: none;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}
.react-datepicker__input-container input {
    width: 100%;
    text-align: center;
    height: 40px;
}

.react-date-picker__calendar-button.react-date-picker__button {
    background: url('../../assets/imgs/calendar.svg') no-repeat center / contain;
}
.react-date-picker__calendar-button.react-date-picker__button svg {
    display: none;
}
.react-date-picker__clear-button.react-date-picker__button {
    display: none;
}
.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month::placeholder,
.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day::placeholder,
.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year::placeholder {
    font-size: 0;
}
.react-calendar__tile--active {
    background: #f3bfda;
    border-radius: 20px;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    border-radius: 20px;
}
.account-welcome {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 10px;
    color: #fff;
}
.account-fill {
    font-size: 24px;
    margin-bottom: 40px;
    color: #fff;
}
.account-submit.green {
    background-color: #42d3b0;
    margin-top: 10px;
}
.subjects{
    margin-top: 10px;
    height: unset;
    min-height: 52px;
}

.phoneInputAcc {
    border-radius: 50px !important;
    margin-bottom: 20px;
}

.flagButton  {
    border: none !important;
    background-color: transparent !important;
    padding-left: 15px !important;
}

.flagButton div{
    background-color: transparent !important;
}

.phoneNumberInput::placeholder {
    color: inherit;
    font-size: 14px;
    font-weight: 300;
    text-align: start;
    overflow: visible;
}
.phoneNumberInput:focus::placeholder {
    opacity: 0;
}
.phoneNumberInputError::placeholder {
    color: inherit;
    font-size: 14px;
    font-weight: 300;
    text-align: start;
    overflow: visible;
}
.phoneNumberInputError:focus::placeholder {
    opacity: 0;
}

.phoneNumberInput {
    border-left: 1px solid #fff;
    width: 75%;
    padding: 0px 110px 0 10px;
    height: 52px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-top: -62px;
    margin-bottom: 10px;
    z-index: 2;
    text-align: center;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
}

.phoneNumberInput:placeholder-shown{
    padding: 0px 10px 0 10px;
}
.phoneNumberInput:focus{
    padding: 0px 110px 0 10px;
}

.phoneNumberInputError {
    text-align: center;
    border-left: 1px solid #f26363;
    width: 75%;
    padding: 0px 110px 0 10px;
    height: 52px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-top: -100px;
    margin-bottom: 40px;
    z-index: 2;
}

/*update 16/11/23 */
@media (max-width: 512px) {
    .phoneNumberInput{
        width: 73%;
    }
    .phoneNumberInput::placeholder {
       font-size: 12px;
    }
    .account-fill{
        text-align: center;
    }
    .phoneNumberInput {
        padding: 0px 5px;
    }
    .react-tel-input .selected-flag{
        padding: 0px;
    }
    .react-tel-input .form-control{
        font-size: 12px!important;
    }
    .phoneInput{
        padding-left: 43px!important;
    }
}
/*update 16/11/23 end*/

